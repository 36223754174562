:root {
  --red: #fb0c0f;
  --green: #1b8b0d;
  --yellow: #fcd03f;
  --white: #ffffff;
  --blk: #1f1f1f;

  .red {
    color: var(--red);
  }

  .white {
    color: var(--white);
  }

  .blk {
    color: var(--blk);
  }

  // fonts
  .tr {
    font-family: "Teenagerockshow";
  }

  .ad {
    font-family: "ArchitectsDaughter";
  }

  --ff-td: "Teenagedecay", sans-serif;
  --ff-ad: "ArchitectsDaughter", sans-serif;

  .pnt {
    cursor: pointer;
  }

  .und {
    text-decoration: underline;
    &:hover {
      filter: brightness(0.85);
    }
  }

  .uptx {
    text-transform: uppercase;
  }

  .captx {
    text-transform: capitalize;
  }
}
