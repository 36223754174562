a {
  color: inherit;
  text-decoration: none;
}

ol,
ul,
li {
  list-style-type: none;
}
p {
  cursor: default;
}
* {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,500&family=VT323&display=swap");

@font-face {
  font-family: "Teenagedecay";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/TeenagedecayDemo-e9w16.woff2") format("woff2");
}

@font-face {
  font-family: "ArchitectsDaughter";
  font-style: bold;
  font-weight: 100;
  src: url("../assets/fonts/ArchitectsDaughter-Regular.woff2") format("woff2");
}


p,
div {
  cursor: default;
}


.txtcen {
  text-align: center;
}

.darkenhov {
  transition: 0.1s ease-in-out;

  &:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
    filter: brightness(0.8);
  }
}
