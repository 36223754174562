@import "mixins.scss";
@import "global.scss";
@import "reset.scss";
@import "variables.scss";

.hidden {
  // display: none;
}
.App {
  width: 100vw;
  display: flex;
  flex-direction: column;

  .navbar {
    height: 6rem;
    background-image: url(../assets/imgs/bg.png);
    background-size: contain;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 4rem;
    border-bottom: 0.2rem solid black;
    position: fixed;
    width: 100vw;
    z-index: 500;
    @include mobile {
      padding: 0rem 0.8rem;
      height: 5rem;
    }
    .icons {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      color: var(--white);
      @include mobile {
        gap: 1.2rem;
      }
      .ico {
        font-size: 2.4rem;
        cursor: pointer;
        @include mobile {
          font-size: 2rem;
        }
      }
      .dx {
        width: auto;
        height: 2.7rem;
        cursor: pointer;
        @include mobile {
          height: 2.2rem;
        }
      }
    }
    .buybtn {
      width: auto;
      height: 3.5rem;
      transition: 0.4s;
      cursor: pointer;
      @include mobile {
        height: 2.7rem;
      }
      &:hover {
        transition: 0.4s;
        filter: brightness(0.8);
      }
    }
    .line1 {
    }
  }

  .body {
    background: rgb(27, 139, 13);
    background: linear-gradient(
      180deg,
      rgba(27, 139, 13, 1) 20%,
      rgb(195, 234, 0) 100%
    );
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    @include mobile {
      padding-top: 8rem;
    }
  }
  .top1 {
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 2rem;
    @include mobile {
      flex-direction: column;
    }
    .img1 {
      width: auto;
      height: 30rem;
      @include mobile {
        height: 18rem;
      }
    }
    .t1 {
      width: auto;
      height: 23rem;
      @include mobile {
        height: 9.5rem;
      }
    }
    .buybtn2 {
      position: absolute;
      height: auto;
      width: 20rem;
      bottom: 2rem;
      transition: 0.4s;
      cursor: pointer;
      @include mobile {
        bottom: -1rem;
        width: 13rem;
      }
      &:hover {
        transition: 0.4s;
        filter: brightness(0.8);
      }
    }
  }
  .top2 {
    margin-top: 3rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 2rem;
    @include mobile {
      flex-direction: column-reverse;
    }
    .img2 {
      width: auto;
      height: 33rem;
      @include mobile {
        height: 18rem;
      }
    }
    .t2 {
      width: auto;
      height: 34rem;
      @include mobile {
        height: 17rem;
      }
    }
  }

  .tokenomics {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-bottom: 6rem;
    @include mobile {
      margin-top: 1rem;
    }

    .t3 {
      height: auto;
      width: 70rem;
      @include mobile {
        width: 22.5rem;
      }
    }
    .linearea {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include mobile {
        margin-top: 10rem;
      }

      .line {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 0.1rem solid var(--white);
        color: var(--white);
        border-radius: 2rem;
        /* From https://css.glass */
        background: rgba(6, 109, 6, 0.26);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.4px);
        -webkit-backdrop-filter: blur(7.4px);
        font-size: 1.4rem;
        padding: 2rem 4rem;
        transition: 0.3s;
        @include mobile {
          max-width: 90vw;
          font-size: 1rem;
          padding: 0.3rem 0.2rem;
        }

        &:hover {
          transition: 0.3s;
          filter: brightness(0.85);
        }
      }
    }

    .icons {
      margin-top: 4rem;
      display: flex;
      flex-direction: row;
      gap: 6rem;
      color: var(--white);
      @include mobile {
        margin-top: 2rem;
        margin-bottom: 2rem;
        gap: 2rem;
      }
      .ico {
        font-size: 3.5rem;
        cursor: pointer;
        @include mobile {
          font-size: 2.5rem;
        }
      }
      .dx {
        width: auto;
        height: 3.9rem;
        cursor: pointer;
        @include mobile {
          height: 2.7rem;
        }
      }
    }

    .two {
      position: absolute;
      left: -7rem;
      bottom: 3rem;
      height: auto;
      width: 18rem;
      rotate: 90deg;
      @include mobile {
        rotate: 0deg;
        bottom: -1rem;
        width: 9rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .imgcnt {
      position: absolute;
      top: 5rem;
      left: 2rem;
      @include mobile {
        left: 50%;
        transform: translateX(-50%);
        top: 1.5rem;
      }
    }
    .wheel {
      height: auto;
      width: 20rem;
      @include mobile {
        width: 9.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .footer {
    z-index: 5;
    border-top: 0.2rem solid black;
    height: 6rem;
    background-image: url(../assets/imgs/bg.png);
    background-size: contain;
  }
}
